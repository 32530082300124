import { chiliSentry } from './chiliTrack'
import { PopUp } from './PopUp'
import { Redirect } from './Redirect'
import { Options } from './types'

export class MarketingApi {
  options: Options
  redirect: Redirect
  constructor(options: Options) {
    const defaultHandler = () => true // means process default redirect action
    this.options = Object.freeze(
      Object.assign(options, {
        onError: options.onError || defaultHandler,
        onSuccess: options.onSuccess || defaultHandler,
        onClose: options.onClose || defaultHandler,
      })
    )

    this.redirect = new Redirect(this)
  }

  get form() {
    return this.options.form
  }

  get formId() {
    return this.options.formId as string | number
  }

  get formOptions() {
    return {
      formId: this.options.formId,
      debug: this.options.debug === true,
      map: this.options.map === undefined ? true : this.options.map,
      domain: this.options.domain,
      router: this.options.router,
      locale: this.options.locale,
      ownerId: this.options.ownerId,
      accountId: this.options.accountId,
      type: this.options.type,
      skipOwnershipLogic: this.options.skipOwnershipLogic,
      webHook: this.options.webHook,
      disableRelation: this.options.disableRelation,
      skipFormFallbackUponAccountDQ: this.options.skipFormFallbackUponAccountDQ,
    }
  }

  showCalendar(formType: MarketingApi, data: Record<string, any>, domElement?: HTMLElement) {
    new PopUp(formType, data, domElement, this.options.event).show()
  }

  onError(err: any) {
    let errorText = 'An error has occured'
    if (err) {
      errorText = err.responseText || err.response || err
    }
    console.error('chilipiper >> ', errorText)

    const errorInfo = {
      message: errorText,
      error: err,
    }

    if (this.options.onError) {
      chiliSentry.sentry.captureException(err)
      this.options.onError(errorInfo)
      this.redirect.proceedCloseRedirect()
    }
  }

  onSuccess(data: Record<string, any>) {
    if (this.options.onSuccess) {
      this.options.onSuccess(data)
      this.redirect.proceedRedirect()
    }
  }

  onClose(hasBooked: boolean) {
    if (this.options.onClose && !hasBooked) {
      this.options.onClose()
      this.redirect.proceedCloseRedirect()
    }
  }

  reduceForm(form?: HTMLFormElement) {
    if (form) {
      const mapped = Array.from(form.elements)
        .filter(
          (element: any) =>
            (element.type !== 'radio' || (element.type === 'radio' && element.checked === true)) &&
            element.type !== 'password'
        )
        .filter((element: any) => element.name && element.name !== 'password')
        .reduce((acc: Record<string, string>, element: any) => {
          const name = element.name?.includes('.') ? element.name.split('.').join('') : element.name
          if (element.type === 'checkbox') {
            if (element.checked) acc[name] = element.value
          } else acc[name] = element.value
          return acc
        }, {})
      return mapped
    }
    return {}
  }
}
